














































import { Vue, Component, Prop } from 'vue-property-decorator';

import ClickOutside from '@/directives/ClickOutside';
import VIcon from '@/components/VIcon.vue';

export interface VStatusSelectOption {
  id: string;
  name: string;
  color: string;
}

@Component({
  components: {
    VIcon
  },
  directives: {
    ClickOutside
  }
})
export default class VStatusSelect extends Vue {
  @Prop({ required: true }) readonly options!: VStatusSelectOption[];
  @Prop({ default: null }) readonly selected: string | null;

  private currentStatus: VStatusSelectOption | null = null;
  private currentHovered: string | null = null;

  private selectOpen = false;

  created() {
    this.initializeCurrent();
  }

  private initializeCurrent() {
    if (this.selected) {
      this.currentStatus = this.options.find((opt: VStatusSelectOption) => opt.id === this.selected);
      return;
    }

    this.currentStatus = this.options[0];
  }

  private getBackgroundStyle(color: string) {
    return `background-color: ${color};`;
  }

  private setHovered(option: VStatusSelectOption) {
    this.currentHovered = option.id;
  }

  private unsetHovered() {
    this.currentHovered = null;
  }

  private getCurrentHoveredStyle(option: VStatusSelectOption) {
    return option.id === this.currentHovered ?
      `background-color: ${option.color}60;` : null;
  }

  private toggleSelectStatus() {
    this.selectOpen = !this.selectOpen;
  }

  private hideSelect() {
    this.selectOpen = false;
  }

  private setSelected(option: VStatusSelectOption) {
    this.currentStatus = option;
    this.$emit('input', this.currentStatus);
    this.hideSelect();
  }

  private setNextStatus() {
    const nextIndex = this.options.findIndex((el: VStatusSelectOption) => el.id === this.currentStatus.id);

    if (nextIndex === this.options.length - 1) {
      return;
    }

    this.currentStatus = this.options[nextIndex + 1];
    this.$emit('input', this.currentStatus);
    this.hideSelect();
  }
}
