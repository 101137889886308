




































































































































import { Mixins, Component, Prop } from 'vue-property-decorator';
import BannersMixin, { BannersMixinTags } from '@/mixins/http/BannerMixin';
import Banner, { BannerPayload, BannerStatuses } from '@/models/Banner';
import { easync } from '@/utils/http';
import { showErrorToast, showToast } from '@/utils/Toast';
import Checkbox from '@/components/Checkbox.vue';
import VStatusSelect, { VStatusSelectOption } from '@/components/VStatusSelect.vue';
import FileUploader from "@/components/FileUploader.vue";
import WithErrors from '@/directives/WithErrors';

@Component({
  components: {
    Checkbox,
    VStatusSelect,
    FileUploader
  },
  directives: {
    WithErrors
  }
})
export default class StoreFrontBannerUpsert extends Mixins(BannersMixin) {
  @Prop({ default: null }) readonly value!: Banner | null;

  private banner: Banner | null = null;
  private payload: BannerPayload | null = null;
  private image: any | null = null;

  created() {
    this.updateMode ? this.feedPayload() : this.initializePayload();
  }

  private feedPayload() {
    this.banner = this.value;
    this.payload = BannerPayload.fromData(this.banner);
  }

  private initializePayload() {
    this.payload = new BannerPayload();
  }

  get createMode() {
    return this.value === null;
  }

  get updateMode() {
    return this.value !== null;
  }

  get bannerId() {
    return this.$route.params.bannerId;
  }

  get storeFrontId() {
    return this.$route.params.id;
  }

  get bannerGroupId() {
    return this.$route.params.bannerGroupId;
  }

  get showTag() {
    return `${BannersMixinTags.Show}-${this.bannerId}`;
  }

  get upsertTag() {
    return BannersMixinTags.Update;
  }

  get statusOptions(): VStatusSelectOption[] {
    return BannerStatuses;
  }

  get url() {
    return this.image?.url || this.payload.imageUrl;
  }

  private async updateStatus(status: VStatusSelectOption) {
    this.payload.status = status.id;

    if (this.createMode) {
      return;
    }

    const payload = new FormData();
    payload.append('status', status.id);
    await this.updateExistingBanner(payload);
  }

  private async onSave() {
    this.payload.image = this.image || null;
    const payload = BannerPayload.toFormData(this.payload);

    if (this.createMode) {
      payload.append('bannerGroupId', this.bannerGroupId);
    }

    this.updateMode ? await this.updateExistingBanner(payload) : await this.createNewBanner(payload);
  }

  private async createNewBanner(payload: FormData) {
    if (!this.payload.status) {
      payload.append('status', this.statusOptions[0].id);
    }

    const [data, errors] = await easync(this.createBanner(payload, BannersMixinTags.Create));

    if (data) {
      showToast('Creazione avvenuta con successo!');
      this.$router.push({ name: 'banners_update', params: { id: this.storeFrontId, bannerId: data.id } });
    } else {
      showErrorToast('Si è verificato un errore durante la creazione del Banner');
    }
  }

  private async updateExistingBanner(payload: FormData) {
    const [data, errors] = await easync(this.updateBanner(this.banner.id, payload, BannersMixinTags.Update));

    if (data) {
      showToast('Modifica avvenuta con successo!');
    } else {
      showErrorToast('Si è verificato un errore durante la modifica del Banner');
    }
  }
}
